<template>
  <div class="page">
    <div class="formcontainer staticpage">
      <div class="formbox">
        <h1>
          İLETİ YÖNETİM SİSTEMİ ANONİM ŞİRKETİ <br />
          ALICI İÇİN İYS YOLUYLA HİZMET SAĞLAYICILAR ADINA ALINACAK ONAY ve RET
          HAKKININ KULLANILMASINA YÖNELİK <br />
          KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ AYDINLATMA METNİ <br />
          (“KVKK AYDINLATMA METNİ”)
        </h1>

        <div class="descline">
          <div class="desctext">
            <div>
              <!-- <h4>BrandCode : {{q.brandCode}}</h4>
              <h4>brandName : {{q.brandName}}</h4>
              <h4>iysCode : {{q.iysCode}}</h4>
              <h4>title : {{q.title}}</h4>
              <br v-for="i in 5"> -->

              <p>Kişisel verileriniz bizim için önemlidir!</p>
              <p>
                Kişisel verilerin işlenmesinde başta özel hayatın gizliliği
                olmak üzere kişilerin temel hak ve özgürlüklerinin korunmasını
                amaçlayan 7 Nisan 2016 tarih ve 29677 Sayılı Resmî Gazete’de
                yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu
                (“KVKK” veya “Kanun”)’nun “Veri Sorumlusunun Aydınlatma
                Yükümlülüğü” başlıklı 10. maddesi ile 10 Mart 2018 tarih ve
                30356 sayılı Resmi Gazete’de yayımlanan Aydınlatma
                Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar
                Hakkında Tebliğ uyarınca işbu Aydınlatma Metni ile İLETİ YÖNETİM
                SİSTEMİ ANONİM ŞİRKETİ (“Şirket’’) tarafından İleti Yönetim
                Sistemi (“İleti Yönetim Sistemi Web” ve “İleti Yönetim Sistemi
                Mobil” ve “İleti Yönetim Sistemi Çağrı Merkezi”) aracılığıyla
                işlenen kişisel verileriniz hakkında sizi bilgilendirmeyi
                hedeflemekteyiz.
              </p>

              <h3>1. VERİ SORUMLUSU</h3>

              <p>
                6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun ile
                Ticari İletişim ve Ticari Elektronik İletiler Hakkında
                Yönetmelik uyarınca Ticaret Bakanlığı tarafından ulusal bir
                ticari elektronik ileti yönetim sisteminin kurulmasına karar
                verilmiş ve Türkiye Odalar ve Borsalar Birliği konuyla ilgili
                görevlendirilmiştir. Şirketimiz, Türkiye Odalar ve Borsalar
                Birliği tarafından söz konusu amaç için Türkiye Odalar ve
                Borsalar Birliği Eğitim ve Kültür Vakfı İştiraki olarak
                kurulmuştur.
              </p>
              <p>
                Şirket, kurmuş olduğu ticari elektronik ileti yönetim sistemi
                (“İYS”) aracılığıyla, elektronik ticaret faaliyetinde bulunan
                gerçek ya da tüzel kişi Hizmet Sağlayıcıların arama, mesaj,
                e-posta gibi farklı tipteki ticari elektronik ileti onaylarını
                saklayıp yönetebilecekleri ve ticari elektronik ileti
                alıcılarının da ticari elektronik ileti onayı almasına, reddetme
                hakkını kullanmasına ve şikâyet süreçlerini yönetmesine imkân
                tanıyan ve tüm onayların zaman damgası ile kayıt altına alındığı
                ulusal veri tabanı kurmuştur.
              </p>
              <p>
                Açıklanan hizmetin yerine getirilmesi esnasında işlenen kişisel
                verileriniz bakımından, Şirket, Kanun uyarınca, “Veri Sorumlusu”
                sıfatını haiz olup tarafımıza aşağıda yer verilen iletişim
                bilgileri aracılığıyla ulaşmanız mümkündür.
              </p>
              <p>
                <b>Merkez Adres: </b>TOBB İkiz Kuleleri C Blok Dumlupınar
                Bulvarı No:252, 06530 Çankaya/ANKARA <br />
                <b>Telefon:</b> 0850 333 3338 <br />
                <b>İnternet Adresi: </b>
                <a href="https://iys.org.tr/">https://iys.org.tr/</a><br />
                <b>E-posta Adresi:</b> kvkk@iys.org.tr<br />
              </p>

              <h3>2. İŞLENEN KİŞİSEL VERİLER</h3>

              <p>
                İleti Yönetim Sistemi aracılığıyla veya İleti Yönetim Sistemi
                ile bağlantılı olarak Şirket ile ilişkiniz kapsamında aşağıdaki
                kişisel verileriniz işlenmektedir;
              </p>
              <ol>
                <li>Kimlik Bilgileriniz: ad-soyad, T.C. kimlik numarası,</li>
                <li>
                  İletişim Bilgileriniz: cep telefonu numarası ve e-posta
                  adresi;
                </li>
                <li>
                  Vermiş Olduğunuz Onaya İlişkin Bilgiler: İleti Yönetim Sistemi
                  aracılığı ile elektronik ticaret faaliyetinde bulunan gerçek
                  ya da tüzel kişi Hizmet Sağlayıcı için verdiğiniz ilk ticari
                  elektronik ileti onayları, onaya ilişkin izin tarihi ve saati,
                  iletişim kanalı, izin kaynağı;
                </li>
                <li>
                  Reddetme Hakkının Kullanımına İlişkin Bilgileriniz: reddetme
                  hakkını kullandığınız tarih ve saat, elektronik ticaret
                  faaliyetinde bulunan gerçek ya da tüzel kişi Hizmet Sağlayıcı,
                  iletişim kanalı, reddetme kaynağı;
                </li>
                <li>
                  Ses Kayıt Bilgileriniz: hizmetin çağrı merkezi aracılığıyla
                  tarafınıza sunulmasını tercih etmeniz halinde işlenen ses
                  kaydı veriniz;
                </li>
                <li>
                  Siber Güvenliğe İlişkin İşlem Güvenliği Verileriniz: IP
                  adresi, WEB sayfası ve mobil uygulama erişim logları ile
                  logları içeren siber güvenliğe ilişkin veriler;
                </li>
              </ol>

              <h3>3. KİŞİSEL VERİLERİN İŞLENME AMAÇLARI</h3>

              <p>
                Şirket ile ilişkinize göre ve bu kapsamda elde edilen kişisel
                verileriniz aşağıda yer verilen amaçlarla işlenebilecektir.
              </p>
              <ul>
                <li>
                  Onay vereceğiniz elektronik ticaret faaliyetinde bulunan
                  gerçek ya da tüzel kişi Hizmet Sağlayıcı için ticari
                  elektronik ileti onayınızın alınmasına, ticari elektronik
                  ileti reddetme hakkınızın kullanılması ve ret ve onay
                  süreçlerinin yönetilmesine imkân tanınması,
                </li>
                <li>
                  Ticari elektronik ileti onay-ret süreçlerinin yönetilmesi,
                </li>
                <li>
                  Onay ve redde ilişkin kayıtların tutulması ve saklanması,
                </li>
                <li>
                  Onay vereceğiniz elektronik ticaret faaliyetinde bulunan
                  gerçek ya da tüzel kişi Hizmet Sağlayıcının tarafınıza ticari
                  elektronik ileti hizmeti sunması için kişisel verilerinizin
                  onay verdiğiniz Hizmet Sağlayıcıya aktarılması,
                </li>
                <li>Onay vereceğiniz elektronik ticaret faaliyetinde bulunan gerçek ya da tüzel kişi Hizmet Sağlayıcının tarafınıza ticari elektronik ileti sunması için kişisel verilerinizin ilgili Hizmet Sağlayıcıya aktarılması amacıyla İleti Yönetim Sistemine entegrasyon hizmeti sunan üçüncü kişilere aktarılması; </li>
                <li>
                  Onay vereceğiniz elektronik ticaret faaliyetinde bulunan
                  gerçek ya da tüzel kişi Hizmet Sağlayıcının, verilen onayı
                  teyit edebilmesi ve müşteri ilişkileri süreçlerinin yönetimi,
                </li>
                <li>
                  Onay vereceğiniz elektronik ticaret faaliyetinde bulunan
                  gerçek ya da tüzel kişi Hizmet Sağlayıcının, ticari elektronik
                  ileti gönderim süreçleri kapsamında tarafınızla iletişime
                  geçebilmesi ve size doğru hitap edebilmesi,
                </li>
                <li>
                  Onay vereceğiniz elektronik ticaret faaliyetinde bulunan
                  gerçek ya da tüzel kişi Hizmet Sağlayıcı tarafından şahsınıza
                  özel ticari elektronik ileti sunulması,
                </li>
                <li>
                  Reddetme hakkını kullandığınıza ilişkin olarak önceki bir
                  tarihte onay vermiş olduğunuz gerçek ya da tüzel kişi Hizmet
                  Sağlayıcıya bilgi verilmesi,
                </li>
                <li>
                  Ticari elektronik iletişime ilişkin mevzuattan doğan diğer
                  yükümlülüklerin yerine getirilmesi,
                </li>
                <li>
                  Sözleşmesel ve yasal yükümlülüklerimizin tam ve gereği gibi
                  ifa edilebilmesi,
                </li>
                <li>
                  Kamu kurum ve kuruluşlarından gelen bilgi taleplerinin
                  karşılanabilmesi,
                </li>
                <li>Hukuk işlerinin takibi ve yürütülmesi,</li>
                <li>Doğabilecek uyuşmazlıklarda delil olarak kullanılması,</li>
                <li>
                  Bilgi güvenliği süreçlerinin yürütülmesi amacıyla ziyaret log
                  kayıtlarının tutulması,
                </li>
              </ul>

              <h3>4. İŞLENEN KİŞİSEL VERİLERİN AKTARIMI</h3>

              <p>
                Kişisel verileriniz; Kanun’un kişisel verilerin aktarılmasına
                ilişkin hükümleri kapsamında işbu Aydınlatma Metni’nin 3.
                maddesinde yer alan amaçlarla; yurt içindeki kanunen yetkili
                kamu resmi kurum ve kuruluşlar, kolluk kuvvetleri, mahkemeler ve
                icra müdürlükleri, T.C. Ticaret Bakanlığı, onay vermiş olduğunuz
                Hizmet Sağlayıcı sıfatını haiz elektronik ticaret faaliyetinde
                bulunan gerçek ya da tüzel kişiler ve Hizmet Sağlayıcı gerçek ya da tüzel kişilere İleti Yönetim Sistemi’ne entegrasyon hizmeti sunan üçüncü kişiler ile paylaşılabilecektir.
              </p>

              <h3>
                5. KİŞİSEL VERİLERİN TOPLANMA YÖNTEMLERİ VE HUKUKİ SEBEPLERİ
              </h3>

              <p>
                Şirket, kişisel verilerinizi, Kanun’un 5. maddesi 2. fıkrası
                (a), (ç) ve (e) bentleri uyarınca kanunlarda açıkça öngörülmesi;
                veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
                zorunlu olması ve bir hakkın tesisi, kullanılması veya korunması
                için veri işlemenin zorunlu olması hukuki sebeplerine ve 6563
                sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun’a
                dayanarak Şirketimizle iletişime geçmeniz ve/veya hukuki
                ilişkinizin kurulması esnasında ve söz konusu ilişkinin devamı
                süresince sizden web sitemiz, mobil aplikasyon, ve çağrı
                merkezimiz yoluyla tarafımıza iletilmesi suretiyle işlemektedir.
              </p>

              <h3>6. KİŞİSEL VERİSİ İŞLENEN İLGİLİ KİŞİNİN HAKLARI</h3>

              <p>
                Kanun’un 11. maddesi hükümleri uyarınca kişisel verilerinize
                ilişki olarak aşağıdaki haklarınız bulunmaktadır.
              </p>
              <ul>
                <li>Kişisel verinin işlenip işlenmediğini öğrenme,</li>
                <li>
                  Kişisel verileri işlenmişse; buna ilişkin bilgi talep etme,
                </li>
                <li>
                  Kişisel verilerin işlenme amacını ve bunların amacına uygun
                  kullanılıp kullanılmadığını öğrenme,
                </li>
                <li>
                  Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                  üçüncü kişileri bilme,
                </li>
                <li>
                  Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                  bunların düzeltilmesini isteme
                </li>
                <li>
                  Kişisel verilerin silinmesini veya yok edilmesini isteme,
                </li>
                <li>
                  Kişisel verilerin düzeltilmesi, silinmesi ya da yok edilmesi
                  halinde bu işlemlerin kişisel verilerin aktarıldığı üçüncü
                  kişilere bildirilmesini isteme,
                </li>
                <li>
                  İşlenen kişisel verilerin münhasıran otomatik sistemler
                  vasıtasıyla analiz edilmesi suretiyle veri sahibinin aleyhine
                  bir sonucun ortaya çıkmasına itiraz etme,
                </li>
                <li>
                  Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğraması hâlinde zararın giderilmesini talep etme.
                </li>
              </ul>

              <h3>
                7. HAK VE TALEPLERİNİZ İÇİN BİZİMLE İLETİŞİME GEÇMEK İSTERSENİZ:
              </h3>

              <p>
                Yukarıda belirtilen haklar kapsamında ilgili taleplerinizi,
                “Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğe”
                göre dilekçe veya noter kanalı ile “TOBB İkiz Kuleleri C Blok
                Dumlupınar Bulvarı No:252, 06530 Çankaya/ANKARA” adresinde yer
                alan merkezimize yazılı olarak veya kayıtlı elektronik posta
                (KEP) adresini kullanmak suretiyle
                <a href="mailto:iys@hs04.kep.tr">iys@hs04.kep.tr</a> adresi
                üzerinden veya Şirketimize daha önce bildirilen ve
                sistemlerimizde kayıtlı bulunan elektronik posta adresinizi
                kullanmak suretiyle
                <a href="mailto:kvkk@iys.org.tr">kvkk@iys.org.tr</a> e-posta
                adresi aracılığıyla tarafımıza iletebilirsiniz.
              </p>
              <p>
                Bu kapsamda yapılacak olan şahsen başvurular tarafımızdan
                yapılacak kimlik doğrulamasını takiben kabul edilecek olup
                talepleriniz, niteliğine göre en kısa sürede ve en geç 30 gün
                içinde sonuçlandırılacaktır. Başvuruya yazılı olarak cevap
                verilmesi durumunda, 10 sayfaya kadar ücret alınmayacak olup 10
                sayfanın üzerindeki her sayfa için Veri Sorumlusuna Başvuru Usul
                ve Esasları Hakkında Tebliğ’in 7. maddesinde yer alan işlem
                ücreti alınabilecektir. Başvuruya cevabın CD, flash bellek gibi
                bir kayıt ortamında verilmesi halinde ise kayıt ortamının
                maliyeti tutarında bir ücret talep edilebilecektir.
              </p>
              <br />
              <br />
              <p>
                <b>
                  VERİ SORUMLUSU <br />
                  İLETİ YÖNETİM SİSTEMİ ANONİM ŞİRKETİ
                </b>
              </p>
              <hr />
              <h1>
                İLETİ YÖNETİM SİSTEMİ ANONİM ŞİRKETİ <br />
                ALICI İÇİN İYS YOLUYLA HİZMET SAĞLAYICILAR ADINA ALINACAK ONAY
                ve RET HAKKININ KULLANILMASINA YÖNELİK <br />
                TİCARİ ELEKTRONİK İLETİ AYDINLATMA METNİ
              </h1>
              <p>
                İleti Yönetim Sistemi Anonim Şirketi Alıcı için İYS Yoluyla
                Hizmet Sağlayıcılar Adına Alınacak Onay ve Ret Hakkının
                Kullanılmasına Yönelik Kişisel Verilerin Korunması ve İşlenmesi
                Aydınlatma Metni doğrultusunda kişisel verilerimin aşağıda adı
                geçen Hizmet Sağlayıcı ve bağlı markaları tarafından ticari
                elektronik ileti gönderilmesi amaçlarıyla işlenmesini ve bu
                amaçlarla aşağıda belirtilen iletişim bilgilerime 6563 sayılı
                Elektronik Ticaretin Düzenlenmesi Hakkında Kanun kapsamında
                ticari elektronik ileti gönderilmesini kabul ediyorum.
              </p>

              <p v-if="payload">
                İletişim adresi: {{ payload.recipient || "" }}
              </p>
              <p v-if="payload">
                Hizmet Sağlayıcı: {{ payload.title || "" }} (İYS Numarası:
                {{ payload.iysCode || "" }})
              </p>
              <p v-if="payload">Markalar:</p>
              <ul v-if="payload">
                <li v-for="(item, index) in payload.brands" :key="index">
                  {{ item.brandName }} (Marka İYS Numarası:
                  {{ item.brandCode }})
                </li>
              </ul>

              <p v-if="INIT">YBilgileriniz yükleniyor...</p>
              <p v-if="ERROR">
                Bilgileriniz yüklenemedi. Lüften daha sonra tekrar deneyiniz !!!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Aydinlatma",
  data() {
    return {
      state: "INIT", // LOADED
      sm: "",
      q: {
        brandCode: "",
        brandName: "",
        iysCode: "",
        title: "",
      },
      payload: null,
    };
  },

  computed: {
    INIT() {
      return this.state === "INIT";
    },
    LOADED() {
      return this.state === "LOADED";
    },
    ERROR() {
      return this.state === "ERROR";
    },
  },

  mounted() {
    const { access_code: accessCode } = this.$route.params;
    const baseURL =
      process.env.VUE_APP_SOWREAP_URL || "https://svc.vkk.io/otp-v1-wlist";

    if (accessCode) {
      //  .get(`${baseURL}/query/consent-clarification/${accessCode}`)
      this.$store
        .dispatch("app/sowreap_query", { accessCode })
        .then((res) => {
          if (res.ok) {
            this.$env.CONSOLE.warn("RESPONSE:", res);
            if (res.payload.accessCode === accessCode) {
              this.payload = { ...res.payload.payload };
              this.state = "LOADED";
            } else {
              this.state = "ERROR";
            }
          } else {
            this.state = "ERROR";
          }
        })
        .catch((error) => {
          this.$env.CONSOLE.error("ERROR:", error);
          this.state = "ERROR";
        });
    }
  },
};
</script>

<style scoped>
html,
body {
  scroll-padding-top: 100px;
}

h1,
h1 * {
  font-size: 1.4rem !important;
}
h2,
h2 * {
  font-size: 1.2rem !important;
  text-align: left;
  margin-bottom: 0.6vh;
  padding-bottom: 0.2vh;
}
h3,
h3 * {
  font-size: 1.1rem !important;
  text-align: left;
  margin-bottom: 0.2vh;
  padding-bottom: 0.2vh;
  color: #55565e;
}
h4,
h4 * {
  font-size: 1rem !important;
}
h5,
h5 * {
  font-size: 1rem !important;
  color: #55565e;
}

p > strong {
  display: inline-block;
  position: relative;
  text-indent: -16px;
}

p .staticpage .descline p {
  padding: 0 0 2vh 0;
}
.desctext p {
  margin-bottom: 0.6vh;
}

.desctext {
  display: block;
  width: 100%;
}

ol li,
ul li,
p {
  padding-bottom: 10px;
  line-height: 2;
}
ol li b {
  text-decoration: underline;
}

ul,
ol {
  margin: 10px 0px 15px 60px;
  text-align: left;
}

ul li {
  display: list-item;
  list-style: disc !important;
  text-align: left;
}

ol li {
  display: list-item;
  list-style: upper-roman !important;
  text-align: left;
}

ol {
  list-style-type: upper-roman;
}

.accordion {
  position: relative;
  margin: 1vh 0;
  display: block;
  width: 100%;
  transition: all 0.3s;
}
.accordion * {
  transition: all 0.3s;
}
.accordion li {
  display: block;
  list-style: circle;
  margin: 1vh 5vh;
}
summary {
  font-weight: 500;
  cursor: pointer;
  padding: 2vh;
  margin: 0;
  color: #4da0df;
  outline: none;
  display: flex;
  align-items: center;
}

summary:hover {
  background: #fdfbfa;
}

details * {
  transition: all 0.3s ease-out;
}

details p {
  padding: 2vh !important;
  border-bottom: 1px solid #eee;
}

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  summary {
    display: flex;
    flex-direction: row;
    padding: 4vw;
    align-items: center;
    justify-content: flex-start;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
}
</style>
